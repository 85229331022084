import React from 'react';
import './Form.css';


export default class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = { display: 'Form-contactus Form-hide',
                   email: '', 
                   note: '',
                   contact_msg: 'To get in touch with us, please fill out the form below: ',
                   submit_msg: '', };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.unhide = this.unhide.bind(this);
    this.hide = this.hide.bind(this);
  }

  unhide(e) {
    this.setState({
      display: 'Form-contactus Form-show'
    });
  }

  hide() {
    this.setState({
      display: 'Form-contactus Form-hide'
    });
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({[name]: value});
  }

  handleSubmit(event) {

    let msg = 'Thanks for your submission!';
    let success = true;

    // Email the note using email.js
    if (Boolean(this.state.note) && Boolean(this.state.email)) {
	  this.sendNote("template_H3f7sm5M", {message_html: this.state.note, from_email: this.state.email});
    }
    else {
      msg = "Please provide both an email address and note.";
      success = false;
    }

    this.setState({submit_msg: msg});
    if (success === true) {setTimeout(() => { this.hide(); window.scrollTo(0,0); }, 2000);}
    event.preventDefault();
  }

  sendNote(templateId, variables) {
    let serviceId = "gmail";
	window.emailjs.send( serviceId,
                         templateId,
                         variables
  	).then(res => { console.log('Email successfully sent!') })

  	// Handle errors here 
  	.catch(err => console.error('Error: ', err))
  }

  render() {

    const fStyle: CSSProperties = {
      width: '20em',
      height: '2em',
      verticalAlign: 'middle',
    };

    // Use spread operator to merge style properties inline below
    return (
     <div id="contactus" className={this.state.display}>
       <form id="contactform" onSubmit={this.handleSubmit}>
         <h4 id="contact_msg">{this.state.contact_msg}</h4>
         <h5 id="submit_msg">{this.state.submit_msg}</h5>
         <div name="formdiv">
           <label>Your email address:<br />
             <input name="email" onChange={this.handleChange} type="text" style={fStyle} />
           </label>
           <br /><br />
           <label>Please add a note:<br />
             <textarea name="note" onChange={this.handleChange} style={{...fStyle, ...{height:'6em'}}} />
           </label>
           <br /><br />
         </div>
         <input type="submit" />
       </form>
       <br /><br />
     </div>
    );
  }
}
