import React from 'react';
import './App.css';
import MyForm from './Form';


// function App() {
class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hidden: true };
    this.unhideForm = this.unhideForm.bind(this);
    this.child = React.createRef();
  }

  unhideForm(e) {
    e.stopPropagation();
    this.setState({ hidden: false });
    this.child.unhide(e);
  }

  render() { 

    const hStyle: CSSProperties = {
      marginBottom:0,
      marginTop:0,
      textAlign: 'left',
      fontWeight: 'normal',
    };

    return (
    <div className="App">
      <header className="App-header">
        <div id="top" className="App-top">
          <h1 style={hStyle}>Precode &#123;&#125; Labs</h1>
          <h4 style={hStyle}>security, privacy, trust</h4>
        </div>
        <div className="App-bottom">
          <h6 style={hStyle}>San Francisco | Silicon Valley
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
            &nbsp; &nbsp; &nbsp; &nbsp; 
            <a href="#footer" onClick={this.unhideForm}>Contact Us</a>
            &nbsp; &nbsp; 
          </h6>
        </div>
      </header>
      <MyForm hidden="true" ref={Ref => this.child=Ref } {...this.props} />
      <span id="footer"></span>
    </div>
    );}
}

export default App;
